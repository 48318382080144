// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Constants
import { GROUPS_LIST } from '@constants';
// Transloco
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'companyOwnStockUrl'
})
export class CompanyOwnStockUrlPipe implements PipeTransform {

  /**
   * Creates an instance of PdpUrlPipe.
   * @param {TranslocoService} translocoService
   * @param {CommonService} commonService
   * @memberof PdpUrlPipe
   */
  constructor(private readonly translocoService: TranslocoService) {
  }

  /**
   * Transform
   * @param {number} companyId
   * @param {string} marketplace
   * @returns {(string | null)}
   * @memberof CompanyOwnStockUrlPipe
   */
  transform(companyId: number, marketplace: string): string | null {
    if (!companyId || !marketplace) {
      return null;
    }
    const lang = this.translocoService.getActiveLang();
    // Find the system with the matching label (case-insensitive)
    const system = GROUPS_LIST.find(item => item.label.toLowerCase() === marketplace.toLowerCase());

    if (system && system.ownStockUrl) {
      // Replace 'x' with the company ID
      return system.ownStockUrl.replace('x', companyId.toString()) + '/' + lang;
    }

    return GROUPS_LIST[0].url;
  }
}
