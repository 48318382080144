// Angular
import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
// Lodash
import { get } from 'lodash';
// Environments
import { environment } from '@environment';

@Component({
  selector: 'app-google-analytics-gtag',
  template: '',
})
export class GoogleAnalyticsGTagComponent {

  /**
   * Creates an instance of GoogleAnalyticsGTagComponent.
   * @param {Document} document
   * @memberof GoogleAnalyticsGTagComponent
   */
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {

    // Check Is Google-Analytics Enabled
    const isGoogleAnalyticsEnabled: boolean = get(environment, 'googleAnalytics.isEnabled', false);

    // BROWSER
    if (isGoogleAnalyticsEnabled) {
      try {
        // URL
        const gaTagURL: string = 'https://www.googletagmanager.com/gtag';

        // Tracking ID
        const gaTrackId = get(environment, 'googleAnalytics.trackId');

        // Google tag (gtag.js)
        const scriptGtag = this.document.createElement('script') as HTMLScriptElement;
        scriptGtag.src = `${gaTagURL}/js?id=${gaTrackId}`;
        scriptGtag.async = true;
        this.document.head.appendChild(scriptGtag);

        const scriptInit = this.document.createElement('script') as HTMLScriptElement;
        const scriptBody = this.document.createTextNode(`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gaTrackId}');
      `);

        scriptInit.appendChild(scriptBody);
        this.document.head.appendChild(scriptInit);

      } catch (error) {
        console.log('Error while loading Google-Analytic script');
      }
    }
  }
}