// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Constants
import { APP_CONSTANT, GROUP_URL } from '@constants';
// Service
import { CommonService } from '@core/utils';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// Lodash
import { get, includes } from 'lodash';
// Pipe
import { MakeMePrettyPipe } from './make-me-pretty.pipe';

/**
 * Returns VDP Url
 * 60: Systemtest
 * 1: Bildelsbasen
 * 2: Varaosahaku
 * 52: Autocirc
 * 48: Vefsalan
 */
@Pipe({
  name: 'vdpUrl'
})
export class VdpUrlPipe implements PipeTransform {

  /**
   * Creates an instance of VdpUrlPipe.
   * @param {TranslocoService} translocoService
   * @param {CommonService} commonService
   * @memberof VdpUrlPipe
   */
  constructor(
    private readonly translocoService: TranslocoService,
    private readonly makeMePrettyPipe: MakeMePrettyPipe,
    private readonly commonService: CommonService) {
  }

  /**
   * Transform
   * @param vehicleId: any
   */
  transform(vehicleId: any): any {
    const lang = this.translocoService.getActiveLang();
    const label_search = this.translocoService.translate('label_search');
    const label_dismantled_vehicle = this.makeMePrettyPipe.transform(this.translocoService.translate('label_dismantled_vehicle'));
    const user = this.commonService.getFromLocalStorage(APP_CONSTANT.LOGGED_USER);
    const companyGroup = get(user, 'company.group', []);
    const vdpURL: string = `/${lang}/vs/${label_search}/${label_dismantled_vehicle}/s10/_/_/_/ID-${vehicleId}`;
    if (includes(companyGroup, 60)) {
      return `${GROUP_URL.SYSTEM_TEST.url}${vdpURL}`
    } else if (includes(companyGroup, 1)) {
      return `${GROUP_URL.BDB.url}${vdpURL}`
    } else if (includes(companyGroup, 2)) {
      return `${GROUP_URL.VOH.url}${vdpURL}`
    } else if (includes(companyGroup, 52)) {
      return `${GROUP_URL.AUTOCIRC.url}${vdpURL}`
    } else if (includes(companyGroup, 48)) {
      return `${GROUP_URL.VEFSALAN.url}${vdpURL}`
    } else {
      return `${GROUP_URL.BDB.url}${vdpURL}`
    }
  }
}
