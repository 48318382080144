// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitWords'
})
export class LimitWordsPipe implements PipeTransform {

  /**
   * Transform
   * @param {string} value
   * @param {number} limit
   * @returns {string}
   * @memberof LimitWordsPipe
   */
  transform(value: string, limit: number): string {
    // Check if the value is defined
    if (!value) return '';

    // Split the value into words
    const words = value.split(' ');

    // Return the first 'limit' words joined with space and add '...' at the end
    return words.slice(0, limit).join(' ') + (words.length > limit ? ' ...' : '');
  }
}
