// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Directives
import { SelectOnFocusEventDirective, MonthYearDateFormatDirective, ImageLoaderDirective, PhoneNumberDirective } from './directives';
// Utils
import { GlobalErrorHandler, SentryService, WebSocketService } from '@core/utils';
// Pipes
import { ArrayIncludesPipe, FirstLetterPipe, FirstNamePipe, GetObjectPipe, JoinPipe, LimitWordsPipe, MakeLogoPipe, MakeMePrettyPipe, MonthYearDatePipe, PdpUrlPipe, SafePipe, SearchFilterPipe, VdpUrlPipe, VehicleIdPipe, MileToKmPipe, CompanyRatingPipe, CompanyOwnStockUrlPipe } from './pipes';
// Components
import { GoogleAnalyticsGTagComponent } from './components/google-analytics-gtag/google-analytics-gtag.component';

// Common Directives & Pipes
const DirectivesPipes = [
  // Directives
  SelectOnFocusEventDirective,
  MonthYearDateFormatDirective,
  ImageLoaderDirective,
  PhoneNumberDirective,
  // Pipes
  JoinPipe,
  GetObjectPipe,
  SafePipe,
  FirstLetterPipe,
  FirstNamePipe,
  SearchFilterPipe,
  ArrayIncludesPipe,
  MakeLogoPipe,
  MonthYearDatePipe,
  PdpUrlPipe,
  MakeMePrettyPipe,
  VehicleIdPipe,
  VdpUrlPipe,
  LimitWordsPipe,
  MileToKmPipe,
  CompanyRatingPipe,
  CompanyOwnStockUrlPipe
];

@NgModule({
  imports: [CommonModule],
  declarations: [
    GoogleAnalyticsGTagComponent,
    ...DirectivesPipes
  ],
  exports: [
    GoogleAnalyticsGTagComponent,
    ...DirectivesPipes
  ],
  providers: [GlobalErrorHandler, SentryService, WebSocketService, ...DirectivesPipes]
})
export class CoreModule {
}
