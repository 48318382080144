// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns only first name of string
 */
@Pipe({
  name: 'firstName'
})
export class FirstNamePipe implements PipeTransform {

  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value: any, args?: any): any {
    return value.split(' ')[0];
  }
}
