// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { includes, toString } from 'lodash';

/**
 * Returns true when value is found in array
 * otherwise returns false
 */
@Pipe({
  name: 'arrayIncludes'
})
export class ArrayIncludesPipe implements PipeTransform {
  /**
   * Transform
   * @param items: any[]
   * @param value: string
   */
  transform(items: Array<string>, value: string): Boolean {
    if (!items || !value) return false;
    return includes(items, toString(value));
  }
}
